var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { AgeUnits } from '../../clearstep/Types';
import { Partner } from '../../clearstep/proto/partners_pb';
export var ObjType;
(function (ObjType) {
    ObjType["Characteristic"] = "Characteristics";
    ObjType["Question"] = "Questions";
    ObjType["Complaint"] = "Complaints";
    ObjType["CareType"] = "Care Types";
    ObjType["Outcome"] = "Outcomes";
    ObjType["Diagnostic"] = "Diagnostics";
    ObjType["Remark"] = "Remarks";
    ObjType["Rule"] = "Rules";
    ObjType["Vignette"] = "Vignettes";
    ObjType["ComplaintCharacteristic"] = "Complaint Characteristics";
    ObjType["DiagnosticVignette"] = "Diagnostic Vignettes";
    ObjType["TriageSummary"] = "Triage Summaries";
})(ObjType || (ObjType = {}));
export var LanguageType;
(function (LanguageType) {
    LanguageType["EN_US"] = "en-US";
    LanguageType["ES_MX"] = "es-MX";
})(LanguageType || (LanguageType = {}));
export function getTranslation(translations, key) {
    if (!translations || !(key in translations)) {
        return {
            id: 0,
            text: '',
            current: true,
        };
    }
    return translations[key];
}
function setNonEnglishTextKeysToFalse(text) {
    for (var _i = 0, _a = Object.entries(text); _i < _a.length; _i++) {
        var key = _a[_i][0];
        if (key !== LanguageType.EN_US) {
            text[key].current = false;
        }
    }
    return text;
}
export function complaintHasTextChanges(original, edited) {
    var _a, _b;
    var textChanged = ((_a = original.text['en-US']) === null || _a === void 0 ? void 0 : _a.text) !== ((_b = edited.text['en-US']) === null || _b === void 0 ? void 0 : _b.text);
    if (textChanged) {
        var newComplaint_1 = __assign({}, edited);
        newComplaint_1.text = setNonEnglishTextKeysToFalse(newComplaint_1.text);
        return newComplaint_1;
    }
    return edited;
}
export var FlowType;
(function (FlowType) {
    FlowType["Question"] = "question";
    FlowType["Remark"] = "remark";
})(FlowType || (FlowType = {}));
export function questionHasTextChanges(original, edited) {
    var _a, _b, _c;
    var newQuestion = __assign({}, edited);
    var textChanged = ((_a = original.text['en-US']) === null || _a === void 0 ? void 0 : _a.text) !== ((_b = edited.text['en-US']) === null || _b === void 0 ? void 0 : _b.text);
    if (textChanged) {
        newQuestion.text = setNonEnglishTextKeysToFalse(newQuestion.text);
    }
    var optionsMap = {};
    for (var _i = 0, _d = original.characteristics; _i < _d.length; _i++) {
        var characteristic = _d[_i];
        optionsMap[characteristic.id] = characteristic;
    }
    newQuestion.characteristics = [];
    for (var _e = 0, _f = edited.characteristics; _e < _f.length; _e++) {
        var characteristic = _f[_e];
        var newCharacteristic_1 = __assign({}, characteristic);
        if (((_c = optionsMap[characteristic.id]) === null || _c === void 0 ? void 0 : _c.text) !== characteristic.text) {
            newCharacteristic_1.text = setNonEnglishTextKeysToFalse(newCharacteristic_1.text);
        }
        newQuestion.characteristics.push(newCharacteristic_1);
    }
    edited.helpers.forEach(function (helper, i) {
        var _a, _b, _c, _d;
        var helperChanged = !original.helpers[i] ||
            ((_a = helper.content['en-US']) === null || _a === void 0 ? void 0 : _a.text) !== ((_b = original.helpers[i].content['en-US']) === null || _b === void 0 ? void 0 : _b.text);
        if (helperChanged) {
            newQuestion.helpers[i].content = setNonEnglishTextKeysToFalse(helper.content);
        }
        var labelChanged = !original.helpers[i] ||
            ((_c = helper.label['en-US']) === null || _c === void 0 ? void 0 : _c.text) !== ((_d = original.helpers[i].label['en-US']) === null || _d === void 0 ? void 0 : _d.text);
        if (labelChanged) {
            newQuestion.helpers[i].label = setNonEnglishTextKeysToFalse(helper.label);
        }
    });
    return newQuestion;
}
export var QuestionType;
(function (QuestionType) {
    QuestionType["MultiSelect"] = "multi-select";
    QuestionType["SingleSelect"] = "single-select";
    QuestionType["Age"] = "age";
    QuestionType["MultiSelectWithoutDefault"] = "multi-select-without-default";
})(QuestionType || (QuestionType = {}));
export var Severity;
(function (Severity) {
    Severity["None"] = "";
    Severity["Call911"] = "Call 911 Now";
    Severity["ER"] = "Go to ER Now";
    Severity["LaborDelivery"] = "Go to Labor & Delivery Now";
    Severity["CallER"] = "Call ER Now";
    Severity["UrgentCare"] = "Go to Urgent Care Now";
    Severity["DoctorNow"] = "Call Doctor or Seek Care Now";
    Severity["BehavioralHealthNow"] = "Call a Behavioral Health Specialist Now";
    Severity["DentistNow"] = "Call Your Dentist Now";
    Severity["TelehealthNow"] = "Video Visit Now";
    Severity["PoisonCenter"] = "Call Poison Center Now";
    Severity["DoctorWithin24Hours"] = "Contact Doctor Within 24 Hours";
    Severity["BehavioralHealth24Hours"] = "Call a Behavioral Health Specialist Within 24 Hours";
    Severity["DentistWithin24Hours"] = "Call Your Dentist Within 24 Hours";
    Severity["TelehealthWithin24Hours"] = "Video Visit Within 24 Hours";
    Severity["SpecialistOfficeHours"] = "Contact Specialist During Office Hours";
    Severity["DoctorOfficeHours"] = "Contact Doctor During Office Hours";
    Severity["BehavioralHealthOfficeHours"] = "Call a Behavioral Health Specialist Within Office Hours";
    Severity["DentistOfficeHours"] = "Call Your Dentist During Office Hours";
    Severity["TelehealthOfficeHours"] = "Video Visit During Office Hours";
    Severity["HomeQuarantine"] = "Home-Quarantine; Self-Monitor";
    Severity["SelfCare"] = "Self Care at Home";
    Severity["Vaccine"] = "Find Out Where To Get Vaccinated";
    Severity["Testing"] = "Find Out Where To Get Tested";
    Severity["Information"] = "Get More Information";
})(Severity || (Severity = {}));
export var Urgency;
(function (Urgency) {
    Urgency["Optional"] = "Optional";
    Urgency["Urgent"] = "Urgent";
    Urgency["Emergent"] = "Emergent";
    Urgency["Now"] = "Now";
    Urgency["LessThan24Hours"] = "<24 Hours";
    Urgency["OfficeHours"] = "Office Hours";
})(Urgency || (Urgency = {}));
export var DeterminationStatus;
(function (DeterminationStatus) {
    DeterminationStatus["Determined"] = "determined";
    DeterminationStatus["InDetermined"] = "indetermined";
    DeterminationStatus["Undetermined"] = "undetermined";
})(DeterminationStatus || (DeterminationStatus = {}));
// Severities in descending priority order
export var severitiesInPriorityOrder = [
    Severity.Call911,
    Severity.ER,
    Severity.LaborDelivery,
    Severity.CallER,
    Severity.UrgentCare,
    Severity.PoisonCenter,
    Severity.DoctorNow,
    Severity.BehavioralHealthNow,
    Severity.DentistNow,
    Severity.TelehealthNow,
    Severity.DoctorWithin24Hours,
    Severity.BehavioralHealth24Hours,
    Severity.DentistWithin24Hours,
    Severity.SpecialistOfficeHours,
    Severity.TelehealthWithin24Hours,
    Severity.DoctorOfficeHours,
    Severity.BehavioralHealthOfficeHours,
    Severity.DentistOfficeHours,
    Severity.TelehealthOfficeHours,
    Severity.HomeQuarantine,
    Severity.SelfCare,
    Severity.Vaccine,
    Severity.Testing,
    Severity.Information,
];
export var DiagnosticCareType;
(function (DiagnosticCareType) {
    DiagnosticCareType["Hospital"] = "HOSPITAL";
    DiagnosticCareType["LaborAndDelivery"] = "LABOR_AND_DELIVERY";
    DiagnosticCareType["PoisonCenter"] = "POISON_CENTER";
    DiagnosticCareType["UrgentCare"] = "URGENT_CARE";
    DiagnosticCareType["PrimaryCare"] = "PRIMARY_CARE";
    DiagnosticCareType["RetailClinic"] = "RETAIL_CLINIC";
    DiagnosticCareType["Telemedicine"] = "TELEMEDICINE";
    DiagnosticCareType["Dentist"] = "DENTIST";
})(DiagnosticCareType || (DiagnosticCareType = {}));
export var PartnerMapping = (_a = {},
    _a[Partner.CLEARSTEP] = 'clearstep',
    _a[Partner.MOUNTAIN] = 'mountain',
    _a[Partner.CVS] = 'cvs',
    _a[Partner.BAYCARE] = 'baycare',
    _a[Partner.HELLOPATIENTS] = 'hellopatients-demo',
    _a[Partner.RIVERSIDEMEDWELLFORCE] = 'riversidemed-wf',
    _a[Partner.MILLCITYMEDWELLFORCE] = 'millcitymed-wf',
    _a[Partner.GILCHRISTMDWELLFORCE] = 'gilchristmd-wf',
    _a[Partner.MERRIMACKFAMILYMEDWELLFORCE] = 'merrimackfamilymed-wf',
    _a[Partner.MERRIMACKVALLEYINTERNALMEDWELLFORCE] = 'merrimackvalleyinternalmed-wf',
    _a[Partner.CHELMSFORDFAMILYWELLFORCE] = 'chelmsfordfamily-wf',
    _a[Partner.HCA_TRISTAR] = 'hca-tristar',
    _a[Partner.HCA_HEALTHONE] = 'hca-healthone',
    _a[Partner.HCA_LASVEGAS] = 'hca-lasvegas',
    _a[Partner.HCA_FLORIDA] = 'hca-florida',
    _a[Partner.HCA_SANANTONIO] = 'hca-sahealth',
    _a[Partner.HCA_MEDICALCITY] = 'hca-medicalcityhealthcare',
    _a[Partner.NOVANT] = 'novant',
    _a[Partner.AERIAL] = 'aerial',
    _a[Partner.PENN] = 'penn',
    _a[Partner.MAHEALTH] = 'mahealth',
    _a[Partner.NORTHWELL] = 'northwell',
    _a[Partner.MARKETPLACE] = 'marketplace',
    _a[Partner.EMORY] = 'emory',
    _a[Partner.ORTHOPEDIC_INSTITUTE] = 'orthopedic-institute',
    _a[Partner.MOUNT_SINAI] = 'mountsinai',
    _a[Partner.SENTARA] = 'sentara',
    _a[Partner.ARC] = 'arc',
    _a[Partner.TUFTS] = 'tufts',
    _a[Partner.DEMO_PARTNER] = 'demo-partner',
    _a[Partner.BSWH] = 'baylor-scott-white',
    _a[Partner.COREWELLHEALTH] = 'corewellhealth',
    _a[Partner.OCHSNER] = 'ochsner',
    _a[Partner.PAGER] = 'pager',
    _a[Partner.UNKNOWN_PARTNER] = 'unknown-partner',
    _a[Partner.CLEARSTEP_DEMO] = 'clearstep-demo',
    _a);
export function remarkHasTextChanges(original, edited) {
    var _a, _b;
    var newRemark = __assign({}, edited);
    var textChanged = ((_a = original.text['en-US']) === null || _a === void 0 ? void 0 : _a.text) !== ((_b = edited.text['en-US']) === null || _b === void 0 ? void 0 : _b.text);
    if (textChanged) {
        newRemark.text = setNonEnglishTextKeysToFalse(edited.text);
    }
    return newRemark;
}
export var OpType;
(function (OpType) {
    OpType["None"] = "";
    OpType["Char"] = "char";
    OpType["NegChar"] = "neg-char";
    OpType["OneOf"] = "any";
    OpType["TwoOf"] = "two-of";
    OpType["ThreeOf"] = "three-of";
    OpType["All"] = "all";
    OpType["AgeGte"] = "age_gte";
    OpType["AgeLte"] = "age_lte";
})(OpType || (OpType = {}));
export var BodyPartTags;
(function (BodyPartTags) {
    BodyPartTags["Abdomen"] = "Abdomen";
    BodyPartTags["Arm"] = "Arm";
    BodyPartTags["BabySymptoms"] = "Baby Symptoms";
    BodyPartTags["Back"] = "Back";
    BodyPartTags["Buttocks"] = "Buttocks";
    BodyPartTags["Chest"] = "Chest";
    BodyPartTags["Ear"] = "Ear";
    BodyPartTags["Expouse"] = "Exposure";
    BodyPartTags["Eye"] = "Eye";
    BodyPartTags["Face"] = "Face";
    BodyPartTags["FemaleOnly"] = "Female Only";
    BodyPartTags["Flank"] = "Flank";
    BodyPartTags["Foot"] = "Foot";
    BodyPartTags["Genital"] = "Genital";
    BodyPartTags["Hair"] = "Hair";
    BodyPartTags["Hand"] = "Hand";
    BodyPartTags["Head"] = "Head";
    BodyPartTags["Hip"] = "Hip";
    BodyPartTags["Infectious"] = "infectious";
    BodyPartTags["Leg"] = "Leg";
    BodyPartTags["MaleOnly"] = "Male Only";
    BodyPartTags["MentalHealth"] = "Mental Health";
    BodyPartTags["Mouth"] = "Mouth";
    BodyPartTags["Neck"] = "Neck";
    BodyPartTags["Nose"] = "Nose";
    BodyPartTags["Pediatric"] = "Pediatric";
    BodyPartTags["Shoulder"] = "Shoulder";
    BodyPartTags["Skin"] = "Skin";
    BodyPartTags["Stool"] = "Stool";
    BodyPartTags["Systemic"] = "Systemic";
    BodyPartTags["Urinary"] = "Urinary";
})(BodyPartTags || (BodyPartTags = {}));
export function clauseReferences(clause, characteristic) {
    var type = clause.type, char = clause.char, sub = clause.sub;
    switch (type) {
        case OpType.Char:
        case OpType.NegChar:
            return char === characteristic.id;
        default:
            return sub === null || sub === void 0 ? void 0 : sub.some(function (s) { return clauseReferences(s, characteristic); });
    }
}
export function referencedCharacteristicIds(clause) {
    var type = clause.type, char = clause.char, sub = clause.sub;
    switch (type) {
        case OpType.Char:
        case OpType.NegChar:
            return [char];
        default:
            return sub === null || sub === void 0 ? void 0 : sub.map(function (s) { return referencedCharacteristicIds(s); }).flat(1);
    }
}
export function outcomeSummaryBySeverity(severity) {
    switch (severity) {
        case Severity.Call911:
            return 1591288434120;
        case Severity.ER:
            return 1591288449888;
        case Severity.LaborDelivery:
            return 1591288460672;
        case Severity.CallER:
            return 1591288469644;
        case Severity.UrgentCare:
            return 1593116919891;
        case Severity.DoctorNow:
            return 1591288477425;
        case Severity.DentistNow:
            return 1591288484554;
        case Severity.PoisonCenter:
            return 1591288492700;
        case Severity.DoctorWithin24Hours:
            return 1591288515481;
        case Severity.DentistWithin24Hours:
            return 1591288537115;
        case Severity.DoctorOfficeHours:
            return 1591288646399;
        case Severity.DentistOfficeHours:
            return 1591288657154;
        case Severity.HomeQuarantine:
            return 1591288669791;
        case Severity.SelfCare:
            return 1591288672485;
        case Severity.Testing:
            return 1591288672486;
        case Severity.Vaccine:
            return 1591288672487;
        case Severity.Information:
            return 1591288672488;
        default:
            return 0;
    }
}
export function outcomeSeverityColor(severity) {
    switch (severity) {
        case Severity.Call911:
        case Severity.ER:
        case Severity.LaborDelivery:
        case Severity.CallER:
            return '#ff695b';
        case Severity.UrgentCare:
        case Severity.DoctorNow:
        case Severity.DentistNow:
        case Severity.PoisonCenter:
        case Severity.DoctorWithin24Hours:
        case Severity.DentistWithin24Hours:
        case Severity.DoctorOfficeHours:
        case Severity.DentistOfficeHours:
            return '#ffa100';
        case Severity.HomeQuarantine:
        case Severity.SelfCare:
        case Severity.Testing:
        case Severity.Vaccine:
        case Severity.Information:
        default:
            return '#30a661';
    }
}
function newComplaint() {
    return {
        name: '',
        hidden: false,
        coverageRequired: false,
        text: {},
        icd10: '',
        outcomes: [],
        education: {
            id: Date.now() + Math.floor(Math.random() * 10000),
            translations: {
                'en-US': {
                    id: Date.now() + Math.floor(Math.random() * 10000),
                    text: '',
                    current: true,
                },
            },
        },
        bannerText: '',
        flow: [],
        id: Date.now(),
        tags: [],
        diagnosticResources: [],
        classification: '',
    };
}
function newQuestion() {
    return {
        text: {},
        characteristics: [],
        id: Date.now(),
        type: '',
        helpers: [
            {
                label: {},
                content: {},
            },
        ],
        mustBeAsked: false,
    };
}
export function newClause() {
    return {
        type: OpType.None,
        char: 0,
        id: Date.now(),
        sub: [],
        ageInYears: 0,
        ageUnits: AgeUnits.Years,
    };
}
function newCharacteristic() {
    return {
        name: '',
        icd10: '',
        impliedBy: __assign(__assign({}, newClause()), { id: 0 }),
        id: Date.now(),
    };
}
function newVignette() {
    return {
        complaint: 0,
        id: Date.now(),
        severity: Severity.None,
        affirms: [],
        negates: [],
        ageInYears: 0,
        ageUnits: AgeUnits.Years,
    };
}
function newDiagnostic() {
    return {
        name: '',
        productionReady: false,
        displayName: '',
        id: Date.now(),
        careTypes: [],
        determinedBy: [{ urgency: Urgency.Optional, clause: newClause() }],
        CPTCode: '',
    };
}
function newDiagnosticVignette() {
    return {
        complaint: 0,
        id: Date.now(),
        conversationId: '',
        ageInYears: 0,
        ageUnits: AgeUnits.Years,
        affirmedCharacteristics: [],
        negatedCharacteristics: [],
        diagnosticUrgencies: [],
        notProductionReady: false,
    };
}
function newRemark() {
    return {
        text: {},
        id: Date.now(),
    };
}
function newRule() {
    return {
        summary: '',
        id: Date.now(),
        conditions: [],
        effects: [],
    };
}
function newTriageSummary() {
    return {
        text: {},
        id: Date.now(),
        type: '',
    };
}
function newOutcome() {
    return {
        name: '',
        summary: 0,
        description: 0,
        severity: Severity.None,
        education: {
            id: Date.now() + Math.floor(Math.random() * 10000),
            translations: {
                'en-US': {
                    id: Date.now() + Math.floor(Math.random() * 10000),
                    text: '',
                    current: true,
                },
            },
        },
        determinedBy: __assign(__assign({}, newClause()), { id: 0 }),
        id: Date.now(),
        careTypes: [],
        highPriority: false,
    };
}
function newCareType() {
    return {
        type: '',
        details: [],
        determinedBy: __assign(__assign({}, newClause()), { id: 0 }),
        id: Date.now(),
    };
}
export function newObject(t) {
    if (t === ObjType.Complaint) {
        return newComplaint();
    }
    if (t === ObjType.Question) {
        return newQuestion();
    }
    if (t === ObjType.Characteristic) {
        return newCharacteristic();
    }
    if (t === ObjType.Outcome) {
        return newOutcome();
    }
    if (t === ObjType.CareType) {
        return newCareType();
    }
    if (t === ObjType.Remark) {
        return newRemark();
    }
    if (t === ObjType.Rule) {
        return newRule();
    }
    if (t === ObjType.TriageSummary) {
        return newTriageSummary();
    }
    if (t === ObjType.Vignette) {
        return newVignette();
    }
    if (t === ObjType.Diagnostic) {
        return newDiagnostic();
    }
    if (t === ObjType.DiagnosticVignette) {
        return newDiagnosticVignette();
    }
    throw Error("unknown object type: ".concat(t));
}
var nameRegex = /^[a-z0-9-]+$/;
function validateName(o) {
    var name = o.name;
    if (!name) {
        return ["Name is empty"];
    }
    if (!nameRegex.test(name)) {
        return [
            "Name contains non-allowed characters. Must be lowercase, hyphenated, or numbers: ".concat(name),
        ];
    }
    return [];
}
var Library = /** @class */ (function () {
    function Library() {
        var _this = this;
        this.addComplaint = function (c) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, complaints = _a.complaints;
            _this.validateObjUpdateIsSafe(c, ObjType.Complaint);
            idToObj[c.id] = c;
            idToType[c.id] = ObjType.Complaint;
            complaints[c.name] = c;
        };
        this.addQuestion = function (q) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, questions = _a.questions;
            _this.validateObjUpdateIsSafe(q, ObjType.Question);
            idToObj[q.id] = q;
            idToType[q.id] = ObjType.Question;
            questions[q.id] = q;
        };
        this.addCharacteristic = function (c) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, characteristics = _a.characteristics;
            _this.validateObjUpdateIsSafe(c, ObjType.Characteristic);
            idToObj[c.id] = c;
            idToType[c.id] = ObjType.Characteristic;
            characteristics[c.id] = c;
        };
        this.addOutcome = function (o) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, outcomes = _a.outcomes;
            _this.validateObjUpdateIsSafe(o, ObjType.Outcome);
            idToObj[o.id] = o;
            idToType[o.id] = ObjType.Outcome;
            outcomes[o.id] = o;
        };
        this.addCareType = function (o) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, careTypes = _a.careTypes;
            _this.validateObjUpdateIsSafe(o, ObjType.CareType);
            idToObj[o.id] = o;
            idToType[o.id] = ObjType.CareType;
            careTypes[o.id] = o;
        };
        this.addRemark = function (r) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, remarks = _a.remarks;
            _this.validateObjUpdateIsSafe(r, ObjType.Remark);
            idToObj[r.id] = r;
            idToType[r.id] = ObjType.Remark;
            remarks[r.id] = r;
        };
        this.addRule = function (r) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, rules = _a.rules;
            _this.validateObjUpdateIsSafe(r, ObjType.Rule);
            idToObj[r.id] = r;
            idToType[r.id] = ObjType.Rule;
            rules[r.id] = r;
        };
        this.addTriageSummary = function (ts) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, triageSummaries = _a.triageSummaries;
            _this.validateObjUpdateIsSafe(ts, ObjType.TriageSummary);
            idToObj[ts.id] = ts;
            idToType[ts.id] = ObjType.TriageSummary;
            triageSummaries[ts.id] = ts;
        };
        this.addVignette = function (v) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, vignettes = _a.vignettes;
            idToObj[v.id] = v;
            idToType[v.id] = ObjType.Vignette;
            vignettes[v.id] = v;
        };
        this.addDiagnostic = function (d) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, diagnostics = _a.diagnostics;
            _this.validateObjUpdateIsSafe(d, ObjType.Diagnostic);
            idToObj[d.id] = d;
            idToType[d.id] = ObjType.Diagnostic;
            diagnostics[d.id] = d;
        };
        this.addDiagnosticVignette = function (dv) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, diagnosticVignettes = _a.diagnosticVignettes;
            idToObj[dv.id] = dv;
            idToType[dv.id] = ObjType.DiagnosticVignette;
            diagnosticVignettes[dv.id] = dv;
        };
        this.addComplaintMap = function (m) {
            var _a = _this, idToObj = _a.idToObj, idToType = _a.idToType, complaintToCharMap = _a.complaintToCharMap;
            if (!m || !m.id) {
                console.warn('error: addComplaintMap() called with missing map or id: ', m);
                return;
            }
            idToObj[m.id] = m;
            idToType[m.id] = ObjType.ComplaintCharacteristic;
            complaintToCharMap[m.id] = m;
        };
        this.validateComplaint = function (c) {
            var errorMessages = [];
            var text = c.text, outcomes = c.outcomes;
            if (!text) {
                errorMessages.push("Missing text on complaint ".concat(JSON.stringify(c)));
            }
            if (!outcomes.length) {
                if (c.name !== 'patient-services-workflow') {
                    errorMessages.push("Complaint has no outcomes ".concat(JSON.stringify(c)));
                }
            }
            var prevOutcome = null;
            outcomes.forEach(function (id) {
                var o = _this.outcomes[id];
                if (!o) {
                    errorMessages.push("Missing outcome ".concat(id, " in ").concat(JSON.stringify(c)));
                }
                else {
                    if (prevOutcome &&
                        severitiesInPriorityOrder.indexOf(prevOutcome.severity) >
                            severitiesInPriorityOrder.indexOf(o.severity)) {
                        errorMessages.push("Outcome ".concat(prevOutcome.name, " and ").concat(o.name, " misordered for ").concat(c.name));
                    }
                    prevOutcome = o;
                }
            });
            return errorMessages;
        };
        this.validateQuestion = function (q) {
            var errorMessages = [];
            var text = q.text, characteristics = q.characteristics, type = q.type;
            if (!text) {
                errorMessages.push("Missing text on question ".concat(JSON.stringify(q)));
            }
            if (type !== QuestionType.Age && !characteristics.length) {
                errorMessages.push("Question has no answer options ".concat(JSON.stringify(q)));
            }
            if ((type === QuestionType.MultiSelect || type === QuestionType.SingleSelect) &&
                characteristics.length < 2) {
                errorMessages.push("Questions should have two or more answer options ".concat(JSON.stringify(q)));
            }
            characteristics.forEach(function (c) {
                if (c.id !== 0 && !Object.prototype.hasOwnProperty.call(_this.characteristics, c.id)) {
                    errorMessages.push("Missing characteristic ".concat(c.id, " in ").concat(JSON.stringify(q)));
                }
            });
            return errorMessages;
        };
        this.validateCharacteristic = function (characteristic) {
            var name = characteristic.name, impliedBy = characteristic.impliedBy;
            var errorMessages = [];
            if (!name) {
                errorMessages.push("Missing name on characteristic ".concat(JSON.stringify(characteristic)));
            }
            errorMessages.push.apply(errorMessages, _this.validateClause(impliedBy));
            return errorMessages;
        };
        this.validateOutcome = function (o) {
            var summary = o.summary, careTypes = o.careTypes, determinedBy = o.determinedBy, severity = o.severity;
            var errorMessages = [];
            if (!summary) {
                errorMessages.push("Missing summary on outcome ".concat(JSON.stringify(o)));
            }
            if (careTypes && !careTypes.every(function (ct) { return _this.careTypes[ct.id]; })) {
                errorMessages.push("Missing care type on outcome ".concat(JSON.stringify(o)));
            }
            if (!Object.values(Severity).includes(severity) || severity === Severity.None) {
                errorMessages.push("Invalid severity ".concat(JSON.stringify(o)));
            }
            errorMessages.push.apply(errorMessages, _this.validateClause(determinedBy));
            return errorMessages;
        };
        this.validateCareType = function (ct, showJson) {
            if (showJson === void 0) { showJson = false; }
            var id = ct.id, type = ct.type, details = ct.details;
            var errorMessages = [];
            if (!type) {
                errorMessages.push("Missing type on care type".concat(showJson ? " ".concat(JSON.stringify(ct)) : ''));
            }
            else if (Object.values(_this.careTypes).some(function (v) { return type === v.type && id !== v.id; })) {
                errorMessages.push("Duplicate care type ".concat(type));
            }
            // Sets maintain unique keys, so this is a fast check for duplicate items in an array
            if (details.length !== new Set(details.map(function (d) { return d.name; })).size) {
                errorMessages.push("Duplicate care detail name".concat(showJson ? " ".concat(JSON.stringify(ct)) : ''));
            }
            return errorMessages;
        };
        this.validateDiagnostic = function (d) {
            var determinedBy = d.determinedBy, careTypes = d.careTypes;
            var errorMessages = [];
            if (careTypes) {
                careTypes.map(function (careType) {
                    if (!Object.values(DiagnosticCareType).includes(careType)) {
                        errorMessages.push("Invalid care type ".concat(JSON.stringify(d)));
                    }
                });
            }
            if (determinedBy) {
                errorMessages.push.apply(errorMessages, _this.validateUrgencyClauses(determinedBy));
            }
            return errorMessages;
        };
        this.validateRemark = function (r) {
            var text = r.text;
            var errorMessages = [];
            if (!text) {
                errorMessages.push("Missing text on remark ".concat(JSON.stringify(r)));
            }
            return errorMessages;
        };
        this.validateRule = function (r) {
            var summary = r.summary;
            var errorMessages = [];
            if (!summary) {
                errorMessages.push("Missing summary on rule ".concat(JSON.stringify(r)));
            }
            return errorMessages;
        };
        this.validateTriageSummary = function (ts) {
            var text = ts.text, type = ts.type;
            var errorMessages = [];
            if (!text) {
                errorMessages.push("Missing text on triage summary ".concat(JSON.stringify(ts)));
            }
            if (!type) {
                errorMessages.push("Missing type on triage summary ".concat(JSON.stringify(ts)));
            }
            return errorMessages;
        };
        this.validateUrgencyClauses = function (urgencyClauses) {
            var errorMessages = [];
            urgencyClauses.forEach(function (urgencyClause) {
                if (!urgencyClause || !urgencyClause.urgency) {
                    errorMessages.push('Invalid urgency with value of null detected in urgency clauses');
                    return;
                }
                if (!Object.values(Urgency).includes(urgencyClause.urgency)) {
                    errorMessages.push("The provided urgency: ".concat(urgencyClause.urgency, " is invalid"));
                }
                errorMessages.push.apply(errorMessages, _this.validateClause(urgencyClause.clause));
            });
            return errorMessages;
        };
        this.validateClause = function (clause) {
            var char = clause.char, type = clause.type, sub = clause.sub;
            var errorMessages = sub === null || sub === void 0 ? void 0 : sub.map(_this.validateClause).flat();
            switch (type) {
                case OpType.OneOf:
                case OpType.All:
                    // TEMPORARILY REMOVED DUE TO BUG
                    break;
                case OpType.TwoOf:
                    if (sub.length < 3) {
                        errorMessages.push("Need at least 3 sub-clauses for two-of operator ".concat(JSON.stringify(clause)));
                    }
                    break;
                case OpType.ThreeOf:
                    if (sub.length < 4) {
                        errorMessages.push("Need at least 4 sub-clauses for three-of operator ".concat(JSON.stringify(clause)));
                    }
                    break;
                case OpType.Char:
                case OpType.NegChar: {
                    var characteristic = _this.characteristics[char];
                    if (!characteristic) {
                        errorMessages.push("Clause with missing characteristic ".concat(char, " in ").concat(JSON.stringify(clause)));
                    }
                    break;
                }
                default:
                    break;
            }
            return errorMessages;
        };
        this.getComplaintFromOutcome = function (outcomeId) {
            var foundComplaint = Object.values(_this.complaints).find(function (complaint) {
                return complaint.outcomes.some(function (complaintOutcomeId) { return complaintOutcomeId === outcomeId; });
            });
            return foundComplaint !== null && foundComplaint !== void 0 ? foundComplaint : null;
        };
        this.addNewObject = function (obj, type) {
            if (type === ObjType.Complaint) {
                _this.addComplaint(obj);
            }
            else if (type === ObjType.Question) {
                _this.addQuestion(obj);
            }
            else if (type === ObjType.Characteristic) {
                _this.addCharacteristic(obj);
            }
            else if (type === ObjType.Outcome) {
                _this.addOutcome(obj);
            }
            else if (type === ObjType.CareType) {
                _this.addCareType(obj);
            }
            else if (type === ObjType.Remark) {
                _this.addRemark(obj);
            }
            else if (type === ObjType.Rule) {
                _this.addRule(obj);
            }
            else if (type === ObjType.TriageSummary) {
                _this.addTriageSummary(obj);
            }
            else if (type === ObjType.Vignette) {
                _this.addVignette(obj);
            }
            else if (type === ObjType.Diagnostic) {
                _this.addDiagnostic(obj);
            }
            else if (type === ObjType.DiagnosticVignette) {
                _this.addDiagnosticVignette(obj);
            }
            else {
                throw Error("unknown object type: ".concat(type));
            }
        };
        this.deleteObject = function (obj, type) {
            if (type === ObjType.Complaint) {
                delete _this.complaints[obj.name];
            }
            else if (type === ObjType.Question) {
                delete _this.questions[obj.id];
            }
            else if (type === ObjType.Characteristic) {
                delete _this.characteristics[obj.id];
            }
            else if (type === ObjType.Outcome) {
                delete _this.outcomes[obj.id];
            }
            else if (type === ObjType.CareType) {
                delete _this.outcomes[obj.id];
            }
            else if (type === ObjType.Remark) {
                delete _this.remarks[obj.id];
            }
            else if (type === ObjType.Rule) {
                delete _this.rules[obj.id];
            }
            else if (type === ObjType.TriageSummary) {
                delete _this.triageSummaries[obj.id];
            }
            else if (type === ObjType.Vignette) {
                delete _this.vignettes[obj.id];
            }
            else if (type === ObjType.Diagnostic) {
                delete _this.diagnostics[obj.id];
            }
            else if (type === ObjType.DiagnosticVignette) {
                delete _this.diagnosticVignettes[obj.id];
            }
            else {
                throw Error("unknown object type: ".concat(type));
            }
        };
        this.idToObj = {};
        this.idToType = {};
        this.complaints = {};
        this.questions = {};
        this.characteristics = {};
        this.outcomes = {};
        this.careTypes = {};
        this.remarks = {};
        this.rules = {};
        this.triageSummaries = {};
        this.vignettes = {};
        this.diagnostics = {};
        this.diagnosticVignettes = {};
        this.redundantOutcomeClauses = {
            redundantOutcomes: {},
        };
        this.complaintToCharMap = {};
    }
    Library.objTypeToText = function (objType) {
        switch (objType) {
            case ObjType.Characteristic:
                return 'characteristics';
            case ObjType.Complaint:
                return 'complaints';
            case ObjType.Outcome:
                return 'outcomes';
            case ObjType.CareType:
                return 'care types';
            case ObjType.Question:
                return 'questions';
            case ObjType.Remark:
                return 'remarks';
            case ObjType.Rule:
                return 'rules';
            case ObjType.TriageSummary:
                return 'triageSummaries';
            case ObjType.Vignette:
                return 'vignettes';
            case ObjType.Diagnostic:
                return 'diagnostics';
            case ObjType.ComplaintCharacteristic:
                return 'complaintmap';
            case ObjType.DiagnosticVignette:
                return 'diagnostic vignettes';
            default:
                throw new Error("unknown object type: ".concat(objType));
        }
    };
    // Note: This function is intentionally insufficient for determining if the library is valid.
    // It is expected that updates to the library will need to be incremental, and so we want to allow changes
    // to it that make it invalid as long as they're safe with respect to the Library's internal structure.
    Library.prototype.validateObjUpdateIsSafe = function (obj, type) {
        var errorMessages = [];
        var _a = this, idToObj = _a.idToObj, idToType = _a.idToType, complaints = _a.complaints, outcomes = _a.outcomes, characteristics = _a.characteristics, diagnostics = _a.diagnostics;
        var isNew = !Object.prototype.hasOwnProperty.call(idToObj, obj.id);
        if (!isNew) {
            if (!obj.id) {
                errorMessages.push("missing id on ".concat(JSON.stringify(obj)));
            }
            // Make sure the type isn't changing, and that it exists
            if (!Object.prototype.hasOwnProperty.call(idToObj, obj.id)) {
                errorMessages.push("editing non-existent obj ".concat(JSON.stringify(obj)));
            }
            var currentType = idToType[obj.id];
            if (currentType !== type) {
                errorMessages.push("attempting to change type of obj ".concat(JSON.stringify(obj), " from ").concat(currentType, " to ").concat(type));
            }
        }
        // Check that the name is valid if it has one.
        if (type === ObjType.Complaint ||
            type === ObjType.Outcome ||
            type === ObjType.Characteristic ||
            type === ObjType.Diagnostic) {
            errorMessages.push.apply(errorMessages, validateName(obj));
            if (type === ObjType.Complaint) {
                var c_1 = obj;
                if (Object.values(complaints).some(function (v) { return c_1.name === v.name && c_1.id !== v.id; })) {
                    errorMessages.push("duplicate complaint named ".concat(c_1.name));
                }
            }
            else if (type === ObjType.Outcome) {
                var o_1 = obj;
                if (Object.values(outcomes).some(function (v) { return o_1.name === v.name && o_1.id !== v.id; })) {
                    errorMessages.push("duplicate outcome named ".concat(o_1.name));
                }
            }
            else if (type === ObjType.Characteristic) {
                var c_2 = obj;
                if (Object.values(characteristics).some(function (v) { return c_2.name === v.name && c_2.id !== v.id; })) {
                    errorMessages.push("duplicate characteristic named ".concat(c_2.name));
                }
            }
            else if (type === ObjType.Diagnostic) {
                var d_1 = obj;
                if (Object.values(diagnostics).some(function (v) { return d_1.name === v.name && d_1.id !== v.id; })) {
                    errorMessages.push("duplicate diagnostic named ".concat(d_1.name));
                }
            }
        }
        if (type === ObjType.CareType) {
            var ctErrors = this.validateCareType(obj);
            if (ctErrors.length > 0) {
                errorMessages.push.apply(errorMessages, ctErrors);
            }
        }
        return errorMessages;
    };
    Library.prototype.validate = function () {
        var _this = this;
        var errorMessages = [];
        Object.values(this.complaints).forEach(function (complaint) {
            errorMessages.push.apply(errorMessages, _this.validateComplaint(complaint));
        });
        Object.values(this.questions).forEach(function (question) {
            errorMessages.push.apply(errorMessages, _this.validateQuestion(question));
        });
        Object.values(this.characteristics).forEach(function (characteristic) {
            errorMessages.push.apply(errorMessages, _this.validateCharacteristic(characteristic));
        });
        Object.values(this.outcomes).forEach(function (outcome) {
            errorMessages.push.apply(errorMessages, _this.validateOutcome(outcome));
        });
        Object.values(this.careTypes).forEach(function (careType) {
            errorMessages.push.apply(errorMessages, _this.validateCareType(careType, true));
        });
        Object.values(this.remarks).forEach(function (remark) {
            errorMessages.push.apply(errorMessages, _this.validateRemark(remark));
        });
        Object.values(this.rules).forEach(function (rule) {
            errorMessages.push.apply(errorMessages, _this.validateRule(rule));
        });
        Object.values(this.triageSummaries).forEach(function (triageSummary) {
            errorMessages.push.apply(errorMessages, _this.validateTriageSummary(triageSummary));
        });
        Object.values(this.diagnostics).forEach(function (diagnostic) {
            errorMessages.push.apply(errorMessages, _this.validateDiagnostic(diagnostic));
        });
        return errorMessages.flat();
    };
    // TODO(annie): break this up into functional pieces and add unit tests
    Library.prototype.update = function (obj, isNew, isDelete, newType) {
        var idToObj = this.idToObj;
        var id = obj.id;
        var type;
        if (isNew) {
            if (!newType) {
                throw Error('must provide type when `isNew` is true.');
            }
            type = newType;
        }
        else {
            type = this.idToType[id];
        }
        if (isNew) {
            this.addNewObject(obj, type);
            return;
        }
        if (isDelete) {
            this.deleteObject(obj, type);
            return;
        }
        var initialObj = idToObj[id];
        idToObj[id] = obj;
        switch (type) {
            case ObjType.Complaint: {
                var initialName = initialObj.name;
                var updatedComplaint = obj;
                delete this.complaints[initialName];
                this.complaints[updatedComplaint.name] = updatedComplaint;
                break;
            }
            case ObjType.Question: {
                delete this.questions[id];
                this.questions[id] = obj;
                break;
            }
            case ObjType.Characteristic: {
                delete this.characteristics[id];
                this.characteristics[id] = obj;
                break;
            }
            case ObjType.Outcome: {
                delete this.outcomes[id];
                this.outcomes[id] = obj;
                break;
            }
            case ObjType.CareType: {
                delete this.careTypes[id];
                this.careTypes[id] = obj;
                break;
            }
            case ObjType.Remark: {
                delete this.remarks[id];
                this.remarks[id] = obj;
                break;
            }
            case ObjType.Rule: {
                delete this.rules[id];
                this.rules[id] = obj;
                break;
            }
            case ObjType.TriageSummary: {
                delete this.triageSummaries[id];
                this.triageSummaries[id] = obj;
                break;
            }
            case ObjType.Vignette: {
                this.vignettes[id] = obj;
                break;
            }
            case ObjType.Diagnostic: {
                this.diagnostics[id] = obj;
                break;
            }
            case ObjType.DiagnosticVignette: {
                this.diagnosticVignettes[id] = obj;
                break;
            }
            case ObjType.ComplaintCharacteristic: {
                this.complaintToCharMap[id] = obj;
                break;
            }
            default:
                throw Error("unknown object type: ".concat(type));
        }
    };
    Library.prototype.exportAll = function () {
        // Object.keys type in TS is always string[]...
        return this.export(Object.keys(this.idToObj));
    };
    Library.prototype.export = function (ids) {
        var _this = this;
        var _a = this, idToObj = _a.idToObj, redundantOutcomeClauses = _a.redundantOutcomeClauses;
        var w = {
            complaints: new Array(),
            questions: new Array(),
            characteristics: new Array(),
            outcomes: new Array(),
            careTypes: new Array(),
            remarks: new Array(),
            rules: new Array(),
            triageSummaries: new Array(),
            vignettes: new Array(),
            diagnostics: new Array(),
            diagnosticVignettes: new Array(),
            redundantOutcomeClauses: redundantOutcomeClauses,
            complaintToCharMap: {
                id: 0,
                name: '',
                mapping: {},
            },
        };
        ids.forEach(function (id) {
            var obj = idToObj[id];
            var type = _this.idToType[id];
            switch (type) {
                case ObjType.Complaint: {
                    var c = obj;
                    w.complaints.push(c);
                    break;
                }
                case ObjType.Question: {
                    var q = obj;
                    w.questions.push(q);
                    break;
                }
                case ObjType.Characteristic: {
                    var ch = obj;
                    w.characteristics.push(ch);
                    break;
                }
                case ObjType.Outcome: {
                    var o = obj;
                    w.outcomes.push(o);
                    break;
                }
                case ObjType.CareType: {
                    var o = obj;
                    w.careTypes.push(o);
                    break;
                }
                case ObjType.Remark: {
                    var r = obj;
                    w.remarks.push(r);
                    break;
                }
                case ObjType.Rule: {
                    var r = obj;
                    w.rules.push(r);
                    break;
                }
                case ObjType.TriageSummary: {
                    var ts = obj;
                    w.triageSummaries.push(ts);
                    break;
                }
                case ObjType.Vignette: {
                    var v = obj;
                    w.vignettes.push(v);
                    break;
                }
                case ObjType.Diagnostic: {
                    var d = obj;
                    w.diagnostics.push(d);
                    break;
                }
                case ObjType.DiagnosticVignette: {
                    var dv = obj;
                    w.diagnosticVignettes.push(dv);
                    break;
                }
                case ObjType.ComplaintCharacteristic: {
                    w.complaintToCharMap = obj;
                    break;
                }
                default:
                    throw Error("unknown object type: ".concat(type));
            }
        });
        return w;
    };
    Library.prototype.convertModifiedObjectsToIds = function (modifiedObjects) {
        // Iterate through all objects of every type, add id to modified set
        var modifiedIds = new Set();
        Object.values(modifiedObjects).forEach(function (value) {
            if (Array.isArray(value)) {
                value.forEach(function (object) {
                    if (Object.prototype.hasOwnProperty.call(object, 'id')) {
                        modifiedIds.add(object.id);
                    }
                });
            }
        });
        if (!!modifiedObjects.complaintToCharMap && !!modifiedObjects.complaintToCharMap.id) {
            modifiedIds.add(modifiedObjects.complaintToCharMap.id);
        }
        return modifiedIds;
    };
    return Library;
}());
export default Library;
export function mergeWithData(l, data) {
    (data.complaints || []).forEach(l.addComplaint);
    (data.questions || []).forEach(l.addQuestion);
    (data.characteristics || []).forEach(l.addCharacteristic);
    (data.outcomes || []).forEach(l.addOutcome);
    (data.careTypes || []).forEach(l.addCareType);
    (data.remarks || []).forEach(l.addRemark);
    (data.rules || []).forEach(l.addRule);
    (data.triageSummaries || []).forEach(l.addTriageSummary);
    (data.vignettes || []).forEach(l.addVignette);
    (data.diagnostics || []).forEach(l.addDiagnostic);
    (data.diagnosticVignettes || []).forEach(l.addDiagnosticVignette);
    l.redundantOutcomeClauses = data.redundantOutcomeClauses;
    l.addComplaintMap(data.complaintToCharMap);
}
